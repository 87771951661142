<script>
  import { initializeApp } from 'firebase/app';
  import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
  import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
  import { getDatabase, ref as dbRef, push, set, onValue } from 'firebase/database';
  import { onMount } from 'svelte';

  const firebaseConfig = {
    apiKey: "AIzaSyAAgLvjnRHIGaPbCBOCwdlnSbwUL3AO3to",
    authDomain: "ilostmyipad.firebaseapp.com",
    databaseURL: "https://ilostmyipad-default-rtdb.firebaseio.com",
    projectId: "ilostmyipad",
    storageBucket: "ilostmyipad.appspot.com",
    messagingSenderId: "1096571522428",
    appId: "1:1096571522428:web:80c118b02e9255266928f9",
    measurementId: "G-W353NL1TTB"
  };
  
    const firebaseApp = initializeApp(firebaseConfig);
    const auth = getAuth(firebaseApp);
    const storage = getStorage(firebaseApp);
    const db = getDatabase(firebaseApp);

  
    let signedIn = false;
    let fileToUpload = null;
    let uploadProgress = 0;
    let uploadComplete = false;
    let backgroundImageUrl = '';
    let uploadedFileURL = '';
    let imageUrls = [];

   
    function signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log("Google user signed in", result.user.displayName);
          signedIn = true;
        })
        .catch((error) => {
          console.error("Error signing in with Google", error);
        });
    }


    function signOutUser() {
      signOut(auth)
        .then(() => {
          console.log("User signed out");
          signedIn = false;
          uploadProgress = 0;
          uploadComplete = false;
          backgroundImageUrl = '';
          uploadedFileURL = '';
          fileToUpload = null;
          imageUrls = [];
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error signing out", error);
        });
    }


    function handleFileChange(event) {
      fileToUpload = event.target.files[0];
      if (fileToUpload) {
        const reader = new FileReader();
        reader.onload = () => {
          backgroundImageUrl = reader.result;
        };
        reader.readAsDataURL(fileToUpload);
      }
    }

  
    function uploadFile() {
      if (!fileToUpload) {
        console.error("No file selected for upload");
        return;
      }

      const fileRef = storageRef(storage, 'images/' + fileToUpload.name);
      const uploadTask = uploadBytesResumable(fileRef, fileToUpload);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadProgress = progress;
        },
        (error) => {
          console.error("Error uploading file", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              const newImageRef = push(dbRef(db, 'images'));
              set(newImageRef, {
                url: downloadURL,
                timestamp: Date.now()
              });
              uploadedFileURL = downloadURL;
              uploadComplete = true;
              fileToUpload = null;
              backgroundImageUrl = '';
              triggerConfetti();
            })
            .catch((error) => {
              console.error("Error getting download URL", error);
            });
        }
      );
    }

    
    function fetchInitialImages() {
      const imagesRef = dbRef(db, 'images');
      onMount(() => {
        onValue(imagesRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            imageUrls = Object.values(data).map(item => item.url);
          }
        });
      });
    }

   
    function triggerConfetti() {
      const confettiElements = document.querySelectorAll('.confetti-piece');
      confettiElements.forEach((confetti) => {
        confetti.classList.add('confetti-active');
      });
      setTimeout(() => {
        confettiElements.forEach((confetti) => {
          confetti.classList.remove('confetti-active');
        });
      }, 3000);
    }

   
    fetchInitialImages();

   
    function getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

 
    onMount(() => {
      const glowElements = document.querySelectorAll('.glow');
      glowElements.forEach((element) => {
        const randomColor = getRandomColor();
        element.style.setProperty('--glow-color', randomColor);
      });
    });

  </script>

<style>
  .photo-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 15px 0;
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: transparent;
  }

  .photo-item {
    width: 250px;
    height: 250px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }

  .photo-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .photo-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 16px 32px rgba(0, 0, 0, 0.3);
  }

  .glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 20px 10px #04d9ff;
    animation: glow-animation 3.0s infinite alternate;
  }

  @keyframes glow-animation {
    0% {
      box-shadow: 0 0 20px 10px #04d9ff;
    }
    100% {
      box-shadow: 0 0 40px 20px var(--glow-color);
    }
  }

  .confetti {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 3000;
  }

  .confetti-piece {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #ff0;
    opacity: 0;
    animation: confetti-fall 2s ease-in-out forwards;
  }

  @keyframes confetti-fall {
    0% {
      transform: translateY(-100vh);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh);
      opacity: 0;
    }
  }

  .confetti-active .confetti-piece {
    animation-delay: calc(var(--confetti-index) * 0.1s);
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * var(--photo-count)));
    }
  }
    .round-button {
         display: inline-block;
         padding: 10px 20px;
         background-color: transparent;
         text-align: center;
         text-decoration: none;
         border: none;
         border-radius: 60%;
         cursor: pointer;
         font-size: 16px;
         transition: background-color 0.3s ease;
       }

       .round-button:hover {
         background-color: #ccf;
       }
    
    .upload-button {
        display: inline-block;
            padding: 10px 20px;
            background-color: #f0f0f0;
            color: #333;
            text-align: center;
            text-decoration: none;
            border: 2px dashed #4CAF50;
            border-radius: 50%;
            cursor: pointer;
            font-size: 16px;
            transition: border-color 0.3s ease;
          }


       .upload-button:hover {
         background-color: #ccf;
       }
    
    
    .byebye {
        color: #fff;
        background-color: red;
        text-align: center;
        text-decoration: none;
        border: 3px dashed red;
        border-radius: 8px;
        cursor: pointer;
        font-size: 16px;
        margin-left: 20px;
    }
    
    .hello-hello {
        color: white;
        background-color: green;
        text-align: center;
        text-decoration: none;
        border: 3px dashed green;
        border-radius: 8px;
        cursor: pointer;
        font-size: 16px;
        margin-left: 300px;
        margin-bottom: 20px;
    }
    
    
    .uppy {
        border: 5px dashed #000;
        border-radius: 5px;
        margin-left: 5%;
        
    }
    
    
    .da-buttins {
        margin-right: 200px;
        margin-bottom: 20px;
      
    }
    .da-wordz {
    margin-left: 5%;
    }
    
    .da-title {
           text-align: center;
           font-size: 6em;
           font-weight: bold;
           margin-top: 20px;
         }
    
</style>

<main>
  <h1 class="da-title" style="color:purple">Firebase<a style="color:green">&nbsp;Uploader</a></h1>

  {#if !signedIn}
    <button class="round-button" on:click={signInWithGoogle}>
      <svg xmlns="http://www.w3.org/2000/svg" width="55.78" height="52" viewBox="0 0 256 229">
        <path fill="#F9AB00" d="M128 228.542c9.895 0 17.91-8.015 17.91-17.91V55.413h-35.82v155.219c0 9.895 8.015 17.91 17.91 17.91"/>
        <path fill="#5BB974" d="M199.356 112.053C180.043 92.755 151.193 88.845 128 100.307l76.669 76.67c3.164 3.163 8.612 1.91 9.955-2.344c6.746-21.357 1.657-45.64-15.268-62.58"/>
        <path fill="#129EAF" d="M56.644 112.053C75.957 92.755 104.807 88.845 128 100.307l-76.669 76.67c-3.164 3.163-8.612 1.91-9.955-2.344c-6.746-21.357-1.657-45.64 15.268-62.58"/>
        <path fill="#AF5CF7" d="M193.67 52.548c-30.507 0-56.402 20-65.67 47.76h121.25c4.97 0 8.283-5.254 6.03-9.687c-11.523-22.611-34.776-38.073-61.61-38.073"/>
        <path fill="#FF8BCB" d="M140.671 20.101C119.09 41.682 114.926 74.114 128 100.307l85.743-85.743c3.523-3.522 2.15-9.582-2.582-11.119c-24.148-7.836-51.52-2.313-70.49 16.656"/>
        <path fill="#FA7B17" d="M115.329 20.101C136.91 41.682 141.074 74.114 128 100.307L42.257 14.564c-3.523-3.522-2.15-9.582 2.582-11.119c24.148-7.836 51.52-2.313 70.49 16.656"/>
        <path fill="#4285F4" d="M62.33 52.548c30.507 0 56.402 20 65.67 47.76H6.75c-4.97 0-8.283-5.254-6.03-9.687C12.244 68.01 35.497 52.548 62.33 52.548"/>
      </svg>
     <code>Google<br>Sign-in</code>
    </button>
  {/if}

  {#if signedIn}
    <div>
      <input type="file" id="fileInput" on:change={handleFileChange} hidden />
      <label for="fileInput" class="file-label">
        {#if backgroundImageUrl}
          <img src={backgroundImageUrl} alt="File Thumbnail" class="photo-item" />
        {:else}
        <p class="da-wordz" style="color:purple">add images</p>
        <span><svg class="uppy" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" {...$$props}>
            <path fill="#942192" d="M19 13a1 1 0 0 0-1 1v.38l-1.48-1.48a2.79 2.79 0 0 0-3.93 0l-.7.7l-2.48-2.48a2.85 2.85 0 0 0-3.93 0L4 12.6V7a1 1 0 0 1 1-1h7a1 1 0 0 0 0-2H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-5a1 1 0 0 0-1-1M5 20a1 1 0 0 1-1-1v-3.57l2.9-2.9a.79.79 0 0 1 1.09 0l3.17 3.17l4.3 4.3Zm13-1a.89.89 0 0 1-.18.53L13.31 15l.7-.7a.77.77 0 0 1 1.1 0L18 17.21Zm4.71-14.71l-3-3a1 1 0 0 0-.33-.21a1 1 0 0 0-.76 0a1 1 0 0 0-.33.21l-3 3a1 1 0 0 0 1.42 1.42L18 4.41V10a1 1 0 0 0 2 0V4.41l1.29 1.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42" />
        </svg></span>
        {/if}
      </label>
      <div class="da-buttins">
      <button class="hello-hello" on:click={uploadFile}>Upload</button>
      {#if uploadProgress > 0 && uploadProgress < 100}
        <progress value={uploadProgress} max="100">{uploadProgress}%</progress>
      {/if}
      {#if uploadComplete}
        <p>Upload complete! <a href={uploadedFileURL} target="_blank">View uploaded file</a></p>
      {/if}
      <button class="byebye" on:click={signOutUser}>Sign Out</button>
      </div>
    </div>
  {/if}


     <div class="photo-grid-wrapper">
       <div class="photo-grid" style="--photo-count: {imageUrls.length}">
         {#each imageUrls as url}
           <div class="photo-item">
             <img src={url} alt="Uploaded image" />
             <div class="glow"></div>
           </div>
         {/each}
       </div>
     </div>
 </main>
